<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
       <div class="box">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm m-t-20">
            <el-form-item label="算法名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="语言" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择语言">
                <el-option label="python2.X" value="python2.X"></el-option>
                <el-option label="python3.X" value="python3.X"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="算法说明" prop="model">
              <el-input v-model="ruleForm.model"></el-input>
            </el-form-item>
            <el-form-item label="算法编写" prop="model">
              <el-input type="textarea" v-model="ruleForm.desc"></el-input>
            </el-form-item>
            <el-form-item class="btn">
              <el-button type="primary" @click="submitForm('ruleForm')">执行并且导出</el-button>
               <el-button type="primary" @click="resetForm()">取消</el-button>
              <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
          </el-form>
       </div>
    </div>
  </div>

</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑

export default {
  name: 'supportAlgorithm',
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
      return {
        ruleForm: {
          name: '',
          model:'',
          theme:'',
          region: '',
          id:'',
          num:1,
          desc:`# 定义模型
class TheModelClass(nn.Module):
    def __init__(self):
        super(TheModelClass, self).__init__()
        self.conv1 = nn.Conv2d(3, 6, 5)
        self.pool = nn.MaxPool2d(2, 2)
        self.conv2 = nn.Conv2d(6, 16, 5)
        self.fc1 = nn.Linear(16 * 5 * 5, 120)
        self.fc2 = nn.Linear(120, 84)
        self.fc3 = nn.Linear(84, 10)

    def forward(self, x):
        x = self.pool(F.relu(self.conv1(x)))
        x = self.pool(F.relu(self.conv2(x)))
        x = x.view(-1, 16 * 5 * 5)
        x = F.relu(self.fc1(x))
        x = F.relu(self.fc2(x))
        x = self.fc3(x)
        return x`
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
        }
      };
    },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
  },
  methods: {
     submitForm(formName) {
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.$router.push({
                  name: 'qualityModel'
                })
              } else {
                console.log('error submit!!');
                return false;
              }
            });
          },
    resetForm() {
      this.$router.push({
        name: 'qualityModel'
      })
    },
    add(){
      this.dialogAdd = true;
    },
    addUsers(){
      this.dialogAdd = false;
    }
  },
}
</script>

<style scoped>
  .box{
    padding: 0 5vw;
  }
  .box .btn :deep(.el-form-item__content) {
    text-align: center;
        margin: 0!important;
  }
  .box :deep(.el-textarea__inner){
    height: 300px;
  }
</style>
